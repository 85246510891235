<script lang="tsx" setup>
const props = defineProps<{
  src: string
  storeUrl?: string
  isYoutube?: boolean
  title?: string
}>()

function Player() {
  const tags = {
    src: `${props.src}?cc_load_policy=1&listType=playlist&rel=0`,
    width: 560,
    height: 315,
    class: 'w-full rounded-15px bg-neutral-300'
  }

  return props.isYoutube
    ? <iframe {...tags} title="YouTube video player" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
    : <video {...tags} controls autoplay />
}
</script>

<template>
  <PopupView class="p-4 flex-center bg-utility-overlay backdrop-blur-1px" transition="fade-in">
    <template #default="ui">
      <section class="grid gap-6 p-6 w-full max-w-645px bg-shade-light rounded-15px text-center">
        <header class="flex items-center justify-between">
          <h3 class="text-heading-3 text-neutral-700">
            {{ title || 'Tutorial' }}
          </h3>
          <IconButton icon="i-mdi-close" @click="ui.hide" />
        </header>

        <Player v-once />

        <a v-if="storeUrl" :href="storeUrl" target="_blank">
          <CTAButton>
            Install now
            <Icon name="mdi:launch" />
          </CTAButton>
        </a>
      </section>
    </template>

    <template #cta="ui">
      <slot v-bind="ui">
        <IconButton size="lg" :title icon="i-solar-question-circle-bold" @click="ui.show" />
      </slot>
    </template>
  </PopupView>
</template>
